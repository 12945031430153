import $ from "jquery"

const submit_if_needed_for = (pages) => {
  pages.forEach((page) => {
    $.each($(`.edit_${page["type"]}`), (_, form) => {
      page["attributs"].forEach((attribut) => {
        let changed = false;
        $(`#${attribut}_${form.id}`).on("blur", () => {
          if (changed) {
            form.submit();
          }
        });
        $(`#${attribut}_${form.id}`).on("keydown", () => {
          changed = true;
        });
      });
    })
  })
}

export default submit_if_needed_for
