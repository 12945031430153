import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["button", "handle", "input"];

  connect() {
    this.toggle(null, false);
  }

  toggle(event, updateInput = true) {
    let value = this.inputTarget.value;

    if (updateInput) {
      value = value === "1" || value === "true" ? "0" : "1";
      this.inputTarget.value = value;
    }

    if (value === "1" || value == "true") {
      this.buttonTarget.classList.add("bg-blue-600");
      this.buttonTarget.classList.remove("bg-gray-200");
      this.handleTarget.classList.add("translate-x-5");
      this.handleTarget.classList.remove("translate-x-0");
    } else {
      this.buttonTarget.classList.add("bg-gray-200");
      this.buttonTarget.classList.remove("bg-blue-600");
      this.handleTarget.classList.add("translate-x-0");
      this.handleTarget.classList.remove("translate-x-5");
    }
  }
}
