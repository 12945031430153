import { Controller } from "stimulus";
import Choices from "choices.js/public/assets/scripts/choices";

export default class extends Controller {
  static targets = [];
  static values = {
    delimiter: String,
  };

  connect() {
    if (this.element.querySelector("select") !== null) {
      this.elementTarget = this.element.querySelector("select");
    } else {
      this.elementTarget = this.element.querySelector("input");
    }
    this.choices = new Choices(this.elementTarget, {
      editItems: true,
      removeItemButton: true,
      delimiter: this.delimiterValue || ",",
      valueComparer: (a, b) => value.trim() === b.trim(),
      addItemFilter: (value) => {
        if (!value) {
          return false;
        }

        return value.length > 0;
      },
    });
  }

  disconnect() {
    this.choices.destroy();
  }
}
