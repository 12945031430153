import { Controller } from "stimulus";

export default class extends Controller {
  static values = { timeout: Number };

  connect() {
    // Autoclose
    if (this.timeoutValue > 0) {
      setTimeout(() => {
        this.close();
      }, this.timeoutValue);
    }
  }

  close() {
    this.element.remove();
  }
}
