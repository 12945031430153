/* eslint no-console:0 */

import "core-js/stable";
import "regenerator-runtime/runtime";

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "trix";
import "@rails/actiontext";
import "channels";

Rails.start();
ActiveStorage.start();

// Stylesheets
import "./application.css";

// Images
import "../shared/images";

// Controllers
import "controllers";

import submit_if_needed_for from "./submit_on_index.js"

const pages = [{ type: "disease", attributs: ["name"] }, { type: "condition", attributs: ["label"] },
{ type: "vaccine", attributs: ["name", "description"] }, { type: "screening", attributs: ["name"] },
{ type: "valence", attributs: ["name", "abbreviation"] }]

window.onload = () => {
  submit_if_needed_for(pages);
};
