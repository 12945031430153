import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["codeInput", "codesList", "nomenclatureInput", "idInput"];

  inputChanged(event) {
    const value = this.codeInputTarget.value;

    for (let option of this.codesListTarget.options) {
      if (option.dataset.value === value) {
        this.nomenclatureInputTarget.value = option.label;
        this.idInputTarget.value = option.dataset.id;
        return;
      }
    }

    this.nomenclatureInputTarget.value = "";
    this.idInputTarget.value = "";
  }
}
