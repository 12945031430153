import { Controller } from "stimulus";
import flatpickr from "flatpickr/dist/flatpickr";
import "flatpickr/dist/l10n/fr";
import I18n from "i18n/index.js.erb";

export default class extends Controller {
  connect() {
    flatpickr(this.element, {
      allowInput: true,
      locale: I18n.locale,
      altInput: true,
      altFormat: "d-m-Y"
    });
  }
}
