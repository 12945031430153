import { Controller } from "stimulus";
// Urggggh
import $ from "jquery";
import Selectize from "selectize/dist/js/standalone/selectize.min.js";

export default class extends Controller {
  static targets = ["input"];
  static values = {
    url: String,
  };

  connect() {
    const url = this.urlValue;

    this.$select = $(this.inputTarget).selectize({
      delimiter: ",",
      persist: true,
      plugins: ["remove_button"],
      create: function (input, callback) {
        const csrfToken = document.getElementsByName("csrf-token")[0].content;

        const data = {
          tag: {
            name: input,
          },
        };

        fetch(url, {
          method: "POST",
          credentials: "include",
          headers: {
            "X-CSRF-Token": csrfToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then((json) => {
            if (json.id === undefined) {
              console.error(json.message);
            } else {
              callback({
                value: json.id,
                text: json.name,
              });
            }
          });
      },
    });
  }

  disconnect() {
    this.$select[0].selectize.destroy();
  }
}
