import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["profileDropdown", "mobileMenu", "openMobileMenuButton", "closeMobileMenuButton"];

  showProfileDropdown(event) {
    event.preventDefault();
    this.profileDropdownTarget.classList.remove("hidden");
    this.profileDropdownTarget.classList.add("block");
  }

  hideProfileDropdown(event) {
    event.preventDefault();
    this.profileDropdownTarget.classList.remove("block");
    this.profileDropdownTarget.classList.add("hidden");
  }

  toggleMobileMenu(event) {
    event.preventDefault();
    this.mobileMenuTarget.classList.toggle("hidden");
    this.openMobileMenuButtonTarget.classList.toggle("hidden");
    this.closeMobileMenuButtonTarget.classList.toggle("hidden");
  }
}
