import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal", "overlay", "body"];

  connect() {}

  open(event) {
    event.preventDefault();

    this.modalTarget.classList.remove("hidden");

    this.overlayTarget.classList.remove("ease-in", "duration-200");
    this.overlayTarget.classList.add("ease-out", "duration-300", "opacity-100");

    this.bodyTarget.classList.remove(
      "ease-in",
      "duration-200",
      "opacity-0",
      "translate-y-4",
      "sm:translate-y-0",
      "sm:scale-95"
    );
    this.bodyTarget.classList.add("ease-out", "duration-300", "opacity-100", "translate-y-0", "sm:scale-100");
  }

  close(event) {
    event.preventDefault();

    this.modalTarget.classList.add("hidden");

    this.overlayTarget.classList.remove("ease-out", "opacity-100");
    this.overlayTarget.classList.add("ease-in", "duration-200", "opacity-0");

    this.bodyTarget.classList.remove("ease-out", "duration-300", "opacity-100", "translate-y-0", "sm:scale-100");
    this.bodyTarget.classList.add(
      "ease-in",
      "duration-200",
      "opacity-0",
      "translate-y-4",
      "sm:translate-y-0",
      "sm:scale-95"
    );
  }
}
