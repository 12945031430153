import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal"];

  connect() {
    this.opened = false;
  }

  toggle(event) {
    event.preventDefault();

    this.opened ? this.close(event) : this.open(event);
  }

  open(event) {
    event.preventDefault();

    this.modalTarget.classList.remove("hidden");
    this.opened = true;
  }

  close(event) {
    event.preventDefault();

    this.modalTarget.classList.add("hidden");
    this.opened = false;
  }
}
