import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['tagInput', 'tagsList', 'idInput'];

    inputChanged(event) {
        const value = this.tagInputTarget.value;

        for (let option of this.tagsListTarget.options) {
            if (option.dataset.value === value) {
                this.idInputTarget.value = option.dataset.id;
                break;
            }
        }
    }
}